import { render, staticRenderFns } from "./Atalanta.vue?vue&type=template&id=dd084084&scoped=true&"
import script from "./Atalanta.vue?vue&type=script&lang=js&"
export * from "./Atalanta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd084084",
  null
  
)

export default component.exports